'use strict'
const $ = require('jquery')
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/css';
$(() => {

  const profile_slider = new Swiper('.js_modal-profile', {
    slidesPerView: "auto",
    speed: 800,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 40
  })

  $('.js_menu-toggle').on('click', e => {
    $('body').toggleClass('is-menu-active')
  })
  $('.js_close-menu').on('click', e => {
    $('body').removeClass('is-menu-active')
  })
  $('.js_toggle-modal').on('click', e => {
    $('#profile').toggleClass('is-active')
  })

  $('.js_toggle-policy').on('click', e => {
    $('#policy').toggleClass('is-active')
  })

  $('.js_modal-index').on('click', e => {
    const index = e.currentTarget.getAttribute('data-index')
    profile_slider.slideTo(index, 0)
  })

  
});
